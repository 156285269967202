<template>
    <section class="pt-10">
        <v-container>
            <v-row v-if="this.$route.params.id !== 'energon-energy'">
                <v-col cols="6" sm="6" lg="4" v-for="(item, id) in goods" :key="id" :offset-lg="goods.length == 1 ? 4 : 0">
                    <div class="card-box text-center mb-4" @click="openDialog(id)">
                        <img :src="item.picture">
                        <v-btn width="" x-large dark class="font-weight-bold" v-if="$vuetify.breakpoint.lgAndUp">
                            {{btn[lang]}}
                        </v-btn>
                    </div>
                </v-col>
                <v-col sm="6" lg="4" v-if="goods.length > 1">
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col sm="12" lg="4" v-for="(item, id) in goods" :key="id" :offset-lg="goods.length == 1 ? 4 : 0">
                    <div class="card-box text-center mb-4" @click="openDialog(id)">
                        <img :src="item.picture">
                        <v-btn width="" x-large dark class="font-weight-bold" v-if="$vuetify.breakpoint.lgAndUp">
                            {{btn[lang]}}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-center">
                    <a :href="linkPres[lang].energy" target="_blank" v-if="this.$route.params.id === 'energon-energy' ">
                        <v-btn width="170px" x-large class="font-weight-bold" outlined>
                            {{loadPres[lang]}}
                        </v-btn>
                    </a>
                    <a :href="linkPres[lang].scandic" target="_blank" v-else>
                        <v-btn x-large class="font-weight-bold mt-4" outlined>
                            {{loadPres[lang]}}
                        </v-btn>
                    </a>
                </v-col>
            </v-row>
        </v-container>
        <GoodDialog v-if="dialog && dialogContent" :dialogContent="dialogContent" :dialog="dialog" @closeDialog="dialog = false" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import GoodDialog from '../components/GoodDialog.vue'

    export default {
        props: {
            goods: {
                required: true,
                type: Array
            }
        },
        components: {
            GoodDialog
        },
        computed: {
            ...mapGetters({
                lang: "lang/getLang"
            })
        },
        mounted() {
            if (this.goods.length) {
                let goodId = this.$route.hash.replace('#', '')
                let goodToOpen = this.goods.find(item => {
                    return item.id == goodId
                })
                let indexToOpen = this.goods.indexOf(goodToOpen)
                this.openDialog(indexToOpen)
            }
        },
        data() {
            return {
                loadPres: {
                    en: 'Load presentation',
                    de: 'Präsentation laden',
                    ru: 'Презентация'
                },
                linkPres: {
                    en: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_eng_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/02/c_activeenergy_presentation_eng.pdf',
                    },
                    de: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_eng_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/02/c_activeenergy_presentation_eng.pdf',
                    },
                    ru: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/05/activeenergy_presentation.pdf',
                    }
                },
                btn: {
                    en: 'Show details',
                    de: 'Zeige Details',
                    ru: 'Подробнее'
                },
                dialogContent: null,
                dialog: false
            }
        },
        methods: {
            openDialog(index) {
                this.dialog = true
                this.dialogContent = this.goods[index]
                // console.log(index)
            }
        }
    }
</script>