<template>
  <div>
    <v-main app class="py-8">
      <Particles id="tsparticles" :options="particlesOptions" />
      <!-- <section id="inner">
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" lg="6">
              <h2 class="mb-2 wow myFadeup" v-html="mainTitle[lang]">
              </h2>
            </v-col>
            <v-col cols="12" xs="12" lg="6" class="text-right hidden-sm-and-down">
              <router-link to="/" tag="a">
                <v-btn elevation="0" rounded class="mt-4">
                  <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                  {{btn[lang]}}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section> -->

      <Categories />





    </v-main>
  </div>

</template>

<script>
import Categories from '../components/Categories.vue'
import { mapGetters } from 'vuex'

  export default {
      components: {Categories},
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    data() {
      return {
        btn: {
          en: 'Main page',
          de: 'Hauptseite',
          ru: 'Главная страница',
        },
        mainTitle: {
          en: 'Our brands',
          de: 'Unsere Marken',
          ru: 'Коллекции'
        },
        particlesOptions: {
          background: {
            color: {
              value: '#fff'
            }
          },
          particles: {
            number: {
              value: 160,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: "#e5e4e4"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 1,
              random: false,
              anim: {
                enable: false,
                speed: 2.9234779642848423,
                opacity_min: 1,
                sync: false
              }
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600
              }
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bubble"
              },
              onclick: {
                enable: false,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3
              },
              repulse: {
                distance: 400,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              },
            },
          },
          detectRetina: true
        }
      }
    }
  }
</script>