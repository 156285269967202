<template>
  <div>
    <v-main app class="py-8">
      <!-- <section id="inner">
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" lg="6">
              <h1 class="mb-2 wow myFadeup" v-html="mainTitle[lang]">
              </h1>
            </v-col>
            <v-col cols="12" xs="12" lg="6" class="text-right hidden-sm-and-down">
              <router-link to="/" tag="a">
                <v-btn elevation="0" rounded class="mt-0">
                  <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                  {{btn[lang]}}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section> -->

    

      <section class="py-8">
        <v-container>
          <v-row>

            

            <v-col cols="12" lg="12" class="text-center">
              <h1 class="mb-2 wow myFadeup"
              style="color:#B3D598;"
              >{{counter}}%</h1>
               <div class="text-h4 font-weight-regular wow myFadeup" v-html="videoDescr.descr[lang]"></div>
              <div class="text-body-1 font-weight-regular  mt-2 wow myFadeup">
                {{videoDescr.sub[lang]}}
              </div>
            </v-col>
            <v-col class="text-center wow myFadeup" cols="12" lg="12" >
              <img v-if="lang === 'en'" src="https://scandicmints.com/wp-content/uploads/2023/06/new_envir_en.png" class="mob-img" >
              <img v-if="lang === 'ru'" src="https://scandicmints.com/wp-content/uploads/2023/06/new_envir.png" class="mob-img" >
              <img v-if="lang === 'de'" src="https://scandicmints.com/wp-content/uploads/2023/06/new_envir_de.png" class="mob-img" >
            </v-col>

            <v-container class="py-10">
                <v-row>
                      <v-divider></v-divider>
                </v-row>
            </v-container>



            <v-col class="mb-4 text-center"  lg="12">
              <img src="../assets/img/shame.png"  class="mob-img wow myFadeup">
            </v-col>
            <v-col cols="12" lg="12">
              
            </v-col>
            <v-col cols="12" lg="12" class="text-center wow myFadeup mt-4">

              <img src="../assets/img/fscc.svg" style="height:60px;" class="mb-4">
              
              <div class="wow myFadeup text-center text-body-1 font-weight-regular" v-html="videoDescr.title[lang]"></div>
              <br>
                <img src="../assets/img/cert.png" style="width:320px" class="mb-4">
                <!-- <v-icon class="wow myFadeup mb-10" color="#9e854d" x-large>mdi-recycle</v-icon> -->
            </v-col>
            

        

            <v-container class="py-10">
                <v-row>
                      <v-divider></v-divider>
                </v-row>
            </v-container>

            

            
            <v-col sm="4" class="text-center"  v-for="(icon, index) in icons" :key="index">
               <v-icon class="wow myFadeup mb-2" color="#9e854d" x-large>mdi-{{icon.icon}}</v-icon>
               <div class="text-h6 font-weight-regular  mt-2 wow myFadeup">
                {{icon.title[lang]}}
              </div> 
            </v-col>

            <!-- <v-col cols="12" lg="12">
               <div class="wow text-body-1 font-weight-regular myFadeup text-center mt-3" v-html="gmoTxt.title[lang]"></div>
            </v-col> -->
            
            <!-- <v-col cols="12" lg="3">
              <img src="../assets/img/recycle.svg" style="width: 100%;">
            </v-col> -->

          </v-row>
        </v-container>
      </section>
    </v-main>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    mounted(){
      setInterval(()=>{
        if(this.counter == 99){
          return
        }
        this.counter++
      }, 20)
    },
    data() {
      return {
        icons: [
          {
            title: {
              ru: 'Без сахара',
              en: 'Sugar free',
              de: 'Sugar free'
            },
            icon: 'cube-off-outline'
          },
          {
            title: {
              ru: 'Без глютена',
              en: 'Gluten free',
              de: 'Gluten free'
            },
            icon: 'barley-off'
          },
          {
            title: {
              ru: 'Без ГМО',
              en: 'GMO free',
              de: 'GMO free'
            },
            icon: 'cancel'
          },
        ],
        counter: 0,
        gmoTxt: {
          title: {
            en: 'Our products do not contain sugar, GMO, gluten or lactose. We use only natural dyes of natural origin such as spirullina, curcumin, carmin. The bright fiavor is provided bу addition of crushed freeze-dried berries, dried citrus and natural mint.',
            de: 'Unsere Produkte enthalten keinen Zucker, GVO, Gluten oder Laktose. Wir verwenden nur natürliche Farbstoffe natürlichen Ursprungs wie Spirullina, Curcumin, Carmin. Das helle Aroma wird durch die Zugabe von zerkleinerten gefriergetrockneten Beeren, getrockneten Zitrusfrüchten und natürlicher Minze bereitgestellt.',
            ru: 'Our products do not contain sugar, GMO, gluten or lactose. We use only natural dyes of natural origin such as spirullina, curcumin, carmin. The bright fiavor is provided bу addition of crushed freeze-dried berries, dried citrus and natural mint.',
          }
        },
        videoDescr:{
            title:{
                en: 'Our factory has FSSC 22000 certification and ISO 9001-2005 certification.<br>We control every step of production, from package stamping to mints formulation.',
                de: 'Unsere Fabrik ist nach FSSC 22000 und ISO 9001-2005 zertifiziert.<br>Wir kontrollieren jeden Schritt der Produktion, vom Stempeln der Verpackung bis zur Formulierung der Pfefferminzbonbons.',
                ru: 'Наш завод имеет сертификат FSSC 22000 и сертификат ISO 9001-2005.<br> Мы контролируем каждый этап производства, от штамповки упаковки до рецептуры мятных конфет.'
            },
            descr: {
                en: 'Packaging with best recyclability',
                de: 'Verpackung mit bester Recyclingfähigkeit',
                ru: 'Упаковка с лучшей перерабатываемостью',
            },
            sub: {
              en: 'Sustainability is important in the decision making process. Our Scandic tins are 99% recyclable.',
              de: 'Unsere Scandic Dosen sind zu 99 % recycelbar.',
              ru: 'Наши банки Scandic на 99% подлежат вторичной переработке.',
            }
        },
        mainTitle: {
            en: 'Environment',
            de: 'Umfeld',
            ru: 'Окружающая среда',
        },
        btn: {
            en: 'Main page',
            de: 'Hauptseite',
            ru: 'Главная страница',
        }
      }
    }
  }
</script>