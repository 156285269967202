<template>
    <div>
        <section id="energyInner">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="6">
                        <h1 class="mb-2 mt-6 wow myFadeup">{{title[lang]}}</h1>
                        <h2 class="mb-8" style="color:#fff;">{{sub[lang]}}</h2>

                        <div class="text-h5 mb-2 grey--text" v-html="sub2[lang]"></div>
                        <div class="body-1 grey--text text--lighten-3">{{powered[lang]}}</div>
                        <!-- <div class="text-h6 font-weight-regular mb-4 wow myFadeup" >
                            {{descr[lang].val}}
                        </div> -->
                        <!-- <router-link tag="a" to="/about">
                            <v-btn width="200px" x-large dark class="font-weight-bold mt-16 wow fadeIn">
                                {{btn[lang].val}}
                            </v-btn>
                        </router-link> -->
                    </v-col>
                    <v-col cols="12" lg="6">
                       <img src="../assets/img/liquiden.png" width="100%"
                       style="margin-top: -20px;">
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            title: {
                en: 'Active Drive',
                de: 'Aktives Laufwerk',
                ru: 'Active Drive',
            },
            sub: {
                en: 'Energy & focus',
                de: 'Energie & Fokus',
                ru: 'Energy & focus',
            },
            sub2: {
                en: 'Natural caffeine <br>B6, B12 Vitamins ',
                de: 'Natürliches Koffein <br>und die Vitamine B6, B12',
                ru: 'Натуральный кофеин <br>Витамины B6, B12 ',
            },
            powered: {
                en: 'Powerd by Guarana',
                de: 'Angetrieben von Guarana',
                ru: 'На основе Гуараны',
            }
        }
    },
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
}
</script>