<template>
    <section id="p404" class="d-flex justify-center align-center">
        <v-container>
            <v-row>
                <v-col cols="12" lg="12" class="text-center">
                    <h2 v-html="title[lang]">
                    </h2>
                    <router-link to="/" tag="a">
                        <v-btn elevation="0" rounded class="mt-16">
                            <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                            {{btn[lang]}}
                        </v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            title: {
                en: '<span class="font-weight-light">Page not found!</span> <br>404',
                de: '<span class="font-weight-light">Seite nicht gefunden!</span> <br>404',
                ru: '<span class="font-weight-light">Page not found!</span> <br>404',
            },
            btn: {
                en: 'Main page',
                de: 'Hauptseite',
                ru: 'Главная страница',
            }
        }
    },
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
}
</script>