import axios from 'axios'
import {API} from '../config'

const goods = {
	namespaced: true,
	state: {
		categories: [],
        singleCategory: null
  	},
	mutations: {
		SET_CATS(state, categories){
			state.categories = categories
		},
        SET_SINGLE_CAT(state, singleCategory){
            state.singleCategory = singleCategory
        }
	},
	actions: {
		loadCategories({commit}){
			axios.get(`${API}get/categories`)
			.then(res =>{
				commit("SET_CATS", res.data.data)
			})
		},
        loadCategory({commit, state}, slug){
			state.singleCategory = null
            axios.get(`${API}get/category/${slug}`)
            .then(res =>{
                // console.log(res.data.items)
                commit("SET_SINGLE_CAT", res.data.items)
            })
        }
	},
	getters: {
        getCategories(state){
			return state.categories
		},
        getSingleCategory(state){
            return state.singleCategory
        }
	}
}

export default goods


