import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import ui from './ui'
import goods from './goods'
import lang from './lang'
import tables from './tables'


export default new Vuex.Store({
	modules: {
		ui,
		goods,
		lang,
		tables
	}
}) 