<template>
  <v-main app class="py-8" id="main">
    <MainSlider />
    <Goods :goods="singleCategory[lang]" v-if="singleCategory" />
    <!-- <Categories /> -->
    <!-- <Animated /> -->
    <!-- <pre v-if="singleCategory">{{ singleCategory[lang] }}</pre> -->
    <section class=" pt-16 pb-8">
        <v-container>
          <v-row>
            <v-col cols="12" lg="12">
              <div class="main-video">
                <div class="video-box-blur"></div>
                <div class="video-box">

                  <video src="https://scandicmints.com/wp-content/uploads/2020/11/all-.mp4" autoplay="autoplay"
                    loop="loop" muted="muted" playsinline="playsinline"></video>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    <Map />
    <section class="pb-8"  v-view="count" v-if="mainSlides.length && categories.length">
        <v-container>
          <v-row>
            <v-col cols="12" lg="12" class="text-center">
              <h1 class="mb-2 wow myFadeup"
              style="color:#B3D598;"
              >{{counter}}%</h1>
               <div class="text-h4 font-weight-regular wow myFadeup" v-html="videoDescr.descr[lang]"></div>
              <div class="text-body-1 font-weight-regular  mt-2 wow myFadeup">
                {{videoDescr.sub[lang]}}
              </div>
            </v-col>
            <v-col class="text-center wow myFadeup" cols="12" lg="12" >
              <img src="../assets/img/backside.png" class="mob-img" >
            </v-col>
            <!--  -->
            <v-col cols="12">
              <v-divider class="pt-10"></v-divider>
            </v-col>
                      
            <!--  -->
            <v-col sm="4" class="text-center"  v-for="(icon, index) in icons" :key="index">
               <v-icon class="wow myFadeup mb-2" color="#9e854d" x-large>mdi-{{icon.icon}}</v-icon>
               <div class="text-h6 font-weight-regular  mt-2 wow myFadeup">
                {{icon.title[lang]}}
              </div> 
            </v-col>
          </v-row>
        </v-container>
    </section>
    <Partners />
  </v-main>
</template>

<script>
import MainSlider from '@/components/MainSlider.vue'
// import Categories from '@/components/Categories.vue'
// import Animated from '@/components/Animated.vue'
import Goods from '@/components/Goods.vue'
import Map from '@/components/Map.vue'
import Partners from '@/components/Partners.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Home',
  components: {MainSlider, Goods, Map, Partners},
  computed:{
      ...mapGetters({
        lang: "lang/getLang",
        mainSlides: "ui/getMainSlides",
        categories: "goods/getCategories",
        singleCategory: "goods/getSingleCategory",
      })
    },
  methods: {
    count(e){

      if(e.percentTop < 1 && this.counter == 0){
        setInterval(()=>{
          if(this.counter == 99){
            return
          }
          this.counter++
        }, 20)
      }
      
    }
  },
  data(){
    return {
      counter: 0,
      icons: [
          {
            title: {
              ru: 'Без сахара',
              en: 'Sugar free',
              de: 'Sugar free'
            },
            icon: 'cube-off-outline'
          },
          {
            title: {
              ru: 'Без глютена',
              en: 'Gluten free',
              de: 'Gluten free'
            },
            icon: 'barley-off'
          },
          {
            title: {
              ru: 'Без ГМО',
              en: 'GMO free',
              de: 'GMO free'
            },
            icon: 'cancel'
          },
        ],
      videoDescr:{
            descr: {
                en: 'Packaging with best recyclability',
                de: 'Verpackung mit bester Recyclingfähigkeit',
                ru: 'Упаковка с лучшей перерабатываемостью',
            },
            sub: {
              en: 'Sustainability is important in the decision making process. Our Scandic tins are 99% recyclable.',
              de: 'Unsere Scandic Dosen sind zu 99% recycelbar.',
              ru: 'Наши банки Scandic на 99% подлежат вторичной переработке.',
            }
        },
    }
  },
  created(){
    this.$store.dispatch('goods/loadCategory', 'original')
  }
}
</script>
