<template>
    <v-app-bar flat color="transparent" class="main-header"
    :class="{energyHeader : this.$route.params.id === 'energon-energy'}">
        <v-navigation-drawer v-model="sidebar" enable-resize-watcher clipped flat floatin absolute temporary app
            class="py-6">


            <v-list dense nav class="mb-5 px-4">
                <router-link tag="a" :to="item.link" v-for="(item, index) in items" :key="index">
                    <v-list-item link>

                        <v-list-item-content color="black">
                            <v-list-item-title color="black">{{ item.title[lang] }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list>

            <div class="px-4 mb-5">
                <!-- <a href="">
                    <v-btn class="mr-4" fab x-small elevation="0">
                        <v-icon>fab fah fa-xs fa-facebook</v-icon>
                    </v-btn>
                </a> -->
                <a href="https://www.instagram.com/scandic_mints/" target="_blank">
                    <v-btn class="mr-4" fab x-small elevation="0">
                        <v-icon>fab fah fa-xs fa-instagram</v-icon>
                    </v-btn>
                </a>
                <a href="https://www.youtube.com/channel/UC1ahQGurUXqPRQBCUBN9eXA" target="_blank">
                    <v-btn class="mr-4" fab x-small elevation="0">
                        <v-icon>fab fah fa-xs fa-youtube</v-icon>
                    </v-btn>
                </a>
            </div>

            <v-list dense nav class="mb-5 px-4">
                <v-list-item link href="mailto:kd@energongroup.ru">
                    <v-list-item-icon>
                        <v-icon>mdi-email-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title color="black">
                            kd@energongroup.ru
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link href="tel:+78129037613">
                    <v-list-item-icon>
                        <v-icon>mdi-phone-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title color="black">
                            +7 812-903-76-13
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                        <a :href="linkPres[lang].energy"
                        target="_blank" v-if="this.$route.params.id === 'energon-energy' ">
                            <v-btn x-large class="font-weight-bold mt-4" outlined>
                                {{loadPres[lang]}}
                            </v-btn>
                        </a>
                        <a :href="linkPres[lang].scandic"
                        target="_blank" v-else>
                            <v-btn x-large class="font-weight-bold mt-4" outlined>
                                {{loadPres[lang]}}
                            </v-btn>
                        </a>
                    </v-list-item>
            </v-list>


        </v-navigation-drawer>

        <v-container>
            <v-row>
                <v-col lg="4" order-lg="2" :class="{'text-center' : $vuetify.breakpoint.lgAndUp}">
                    <router-link tag="a" to="/">
                        <img src="../assets/img/energon.png" style="height:50px;" 
                        v-if="this.$route.params.id === 'energon-energy'" class="my-4 energy-logo">
                        <img src="../assets/img/logo.svg" v-else class="logo">
                    </router-link>
                </v-col>
                <v-col lg="4" class="d-flex justify-start align-center" order-lg="1">
                    
                    <v-app-bar-nav-icon class="hidden-sm-and-down" color="black" large @click="openSidebar"></v-app-bar-nav-icon>


                    <v-menu bottom right :offset-y="true" open-on-hover>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn class="ml-4" small color="transparent" v-bind="attrs" v-on="on" elevation="0">
                                <v-icon color="grey lighten-1">mdi-web</v-icon>
                                <div class="ml-1 text-button">{{lang}}</div>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link v-for="(item, index) in languages" :key="index" @click="changeLang(item.value)">
                                <v-list-item-title>
                                    <v-avatar size="18" class="mr-1">
                                        <img :src="item.img">
                                    </v-avatar>
                                    {{item.name}}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-app-bar-nav-icon class="hidden-md-and-up" color="black" large @click="openSidebar"></v-app-bar-nav-icon>




                </v-col>
    
                <v-col lg="4" class="d-flex justify-end align-center" v-if="$vuetify.breakpoint.lgAndUp" order-lg="3">
                    <a :href="linkPres[lang].energy"
                    target="_blank" v-if="this.$route.params.id === 'energon-energy' ">
                        <v-btn width="170px" x-large class="font-weight-bold" outlined>
                            {{loadPres[lang]}}
                        </v-btn>
                    </a>
                    <a :href="linkPres[lang].scandic"
                    target="_blank" v-else>
                        <v-btn width="170px" x-large class="font-weight-bold" outlined>
                            {{loadPres[lang]}}
                        </v-btn>
                    </a>
                    <v-btn width="170px" x-large dark class="font-weight-bold ml-4" @click="$vuetify.goTo(target, options)">
                       {{btn[lang].val}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

    </v-app-bar>
</template>


<script>
import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters({
                lang: "lang/getLang"
            })
        },
        data() {
            return {
                target: '#contacts',
                options: {
                    duration: 200,
                    offset: 0,
                    easing: 'easeInOutCubic',
                },
                loadPres: {
                    en: 'Load presentation',
                    de: 'Präsentation laden',
                    ru: 'Презентация'
                },
                linkPres: {
                    en: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_eng_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/02/c_activeenergy_presentation_eng.pdf',
                    },
                    de: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_eng_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/02/c_activeenergy_presentation_eng.pdf',
                    },
                    ru: {
                        scandic: 'https://scandicmints.com/wp-content/uploads/2023/06/scandic_2023.pdf',
                        energy: 'https://scandicmints.com/wp-content/uploads/2022/05/activeenergy_presentation.pdf',
                    }
                },
                btn: {
                    en: {
                        val: 'Send Request'
                    },
                    de: {
                        val: 'Anfrage Senden'
                    },
                    ru: {
                        val: 'Отправить запрос'
                    }
                },
                languages: [
                    {
                        name: 'English',
                        value: 'en',
                        img: require('../assets/img/uk.png')
                    },
                    {
                        name: 'Deutsch',
                        value: 'de',
                        img: require('../assets/img/den.png')
                    },
                    {
                        name: 'Русский',
                        value: 'ru',
                        img: require('../assets/img/ru.png')
                    },
                ],
            
                sidebar: false,
                items: [{
                        title: {
                            en: 'Main page',
                            de: 'Hauptseite',
                            ru: 'Главная страница'
                        },
                        icon: 'mdi-home-outline',
                        link: '/'
                    },
                    {
                        title: {
                            en: 'About',
                            de: 'Über uns',
                            ru: 'О компании'
                        },
                        icon: 'mdi-rocket-outline',
                        link: '/about'
                    },
                    // {
                    //     title: {
                    //         en: 'Our brands',
                    //         de: 'Unsere Marken',
                    //         ru: 'Коллекции'
                    //     },
                    //     icon: 'mdi-candy-outline',
                    //     link: '/collection'
                    // },
                    {
                        title: {
                            en: 'Sales geography',
                            de: 'Vertriebsgeographie',
                            ru: 'География продаж'
                        },
                        icon: 'mdi-compass-outline',
                        link: '/sales-geography'
                    },
                    {
                        title: {
                            en: 'Environment',
                            de: 'Umfeld',
                            ru: 'Окружающая среда',
                        },
                        icon: 'mdi-compass-outline',
                        link: '/environment'
                    },
                    {
                        title: {
                            en: 'Contacts',
                            de: 'Kontakte',
                            ru: 'Контакты'
                        },
                        icon: 'mdi-account-box-outline',
                        link: '/contacts'
                    },
                    {
                        title: {
                            en: 'Privacy policy',
                            de: 'Datenschutz-Bestimmungen',
                            ru: 'Политика конфиденциальности'
                        },
                        icon: 'mdi-account-box-outline',
                        link: '/policy'
                    },
                    {
                        title: {
                            en: 'Goals',
                            de: 'Ziele',
                            ru: 'Цели'
                        },
                        icon: 'mdi-checkered',
                        link: '/goals'
                    }

                ]
            }
        },
        methods: {
            openSidebar() {
                this.sidebar = !this.sidebar
            },
            changeLang(val){
                this.$store.dispatch("lang/changeLang", val)
            }
        }
    }
</script>