<template>
    <section id="cat" v-if="categories.length" style="z-index:10;">
        <v-container>
            <v-row>
                <v-col cols="12" lg="12" class="text-center" style="z-index:10;">
                    <h2 class="mb-5 myFadeup wow" style="z-index:10;" v-html="title[lang].title">
                       
                    </h2>
                </v-col>
                <v-col class="text-center" lg="6" v-for="(item, index) in categories" :key="index">
                    <router-link tag="a" :to="`/collection/${item.en.slug}`">
                    <div class="big-card mb-6">
                        <div class="big-card-img">
                        <div class="zoom-img wow" :style="{'background-image': 'url(' + item.en.back + ')'}"></div>
                        </div>
                        <div class="card-hover"></div>
                        <img :src="item.en.img" class="mini-tin wow fadeIn" >
                        
                        <h3>
                            <span>{{item.en.name}}</span>
                        </h3>
                        <!-- <div class="wow black-fon"></div> -->
                    </div>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            categories: "goods/getCategories",
            lang: "lang/getLang"
        })
    },
    data(){
        return{
            title: {
                en: {
                    title: '<span class="font-weight-light">Our brands</span>'
                },
                de: {
                    title: '<span class="font-weight-light">Unsere Marken</span>'
                },
                ru: {
                    title: '<span class="font-weight-light">Коллекции</span>'
                }
            }
        }
    }
}
</script>