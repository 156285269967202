import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Particles from "particles.vue";
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import checkView from 'vue-check-view'

import 'swiper/css/swiper.css'


Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VueAwesomeSwiper)
Vue.use(Particles)
Vue.use(checkView)

import ru from 'vuetify/es5/locale/ru'


const lang = JSON.parse(localStorage.getItem("lang"))
if(!lang){
  store.dispatch("lang/changeLang", 'ru');
}else{
  store.dispatch("lang/changeLang", lang);
}
// store.dispatch("lang/VALIDATE", user);


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCxBHNO6mwpgRvTOu9zKrJT3XKE5-cNP7s'
  },
  installComponents: true
})


new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru'
    }
  }),
  render: h => h(App)
}).$mount('#app')
