<template>
  <v-app>
    <transition name="slide" mode="out-in">
      <Preloader v-if="!mainSlides.length && !categories.length" />
    </transition>

    <Header />    
      <router-view></router-view>
    <Contacts v-if="this.$route.name !== 'Notfound' " />
  
    

  </v-app>
</template>

<script>
import './assets/css/style.css'
import Contacts from './components/Contacts.vue'
import {mapGetters} from 'vuex'
import Header from './components/Header.vue'
import Preloader from './components/Preloader.vue'

  export default {
    computed: {
      ...mapGetters({
        mainSlides: "ui/getMainSlides",
        categories: "goods/getCategories",
        singleCategory: "goods/getSingleCategory"
      })
    },
    components: {Header, Contacts, Preloader},
    created(){
      this.$store.dispatch('ui/loadSlider')
      this.$store.dispatch('goods/loadCategories')
    }
  }
</script>


<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  color: #2c3e50; 
  color: #272727;
} */
.slide-leave-active{
	transition: opacity .3s ease;
	opacity: 0;
	animation: slide-out .3s  ease-out forwards;
}
.slide-leave{
	opacity: 1;
	transform: translateX(0px);
}
.slide-enter-active{
	animation: slide-in .3s  ease-out forwards;
	opacity: 1;
}
</style>
