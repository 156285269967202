<template>
    <section id="svgmap">
        <v-container>
            <div class="row">
                <v-col lg="12" class="text-center">
                    <h2 class="mb-0 wow myFadeup" v-html="title[lang].val">
                    </h2>
                </v-col>
                <v-col cols="12" lg="10" class="mx-auto" >
                    <img src="../assets/img/polimap.svg" class="polymap">
                </v-col>
                <v-col lg="10" class="mx-auto text-center">
                    <router-link tag="a" to="/sales-geography">
                        <v-btn width="200px" x-large class="font-weight-bold" outlined>
                            {{btn[lang].val}}
                        </v-btn>
                    </router-link>
                </v-col>
            </div>
        </v-container>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return{
            title: {
                en: {
                    val: '<span class="font-weight-light">Sales geography</span>'
                },
                de: {
                    val: '<span class="font-weight-light">Vertriebsgeographie</span>'
                },
                ru: {
                    val: '<span class="font-weight-light">География продаж</span>'
                }
            },
            descr: {
                en: {
                    val: ''
                },
                de: {
                    val: ''
                },
                ru: {
                    val: ''
                }
            },
            btn: {
                en: {
                    val: 'Explore map'
                },
                de: {
                    val: 'Entdecken'
                },
                ru: {
                    val: 'Посмотреть карту'
                },
            },
        }
    }
}
</script>