<template>
        <v-dialog v-model="dialog" width="700" :fullscreen="$vuetify.breakpoint.xsOnly" @click:outside="closeDialog($emit)">
            <v-card @click.stop>
                <v-toolbar color="grey lighten-3" flat>
                    <v-card-title class="text-h5 font-weight-medium" color="black">
                         <span class="font-weight-light mr-2">{{taste[lang]}}: </span> {{dialogContent.name}}
                    </v-card-title>
                </v-toolbar>

                 <!-- <pre>{{dialogContent}}</pre>  -->


                

                <DialogSlider v-if="dialog && dialogContent.gallery.length" :images="dialogContent.gallery" />


                <v-tabs v-model="tab" background-color="transparent" color="#9E854D" class="mt-4" grow>
                    <v-tab v-for="(item, index) in tabs" :key="index">
                        {{ item[lang] }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <!-- Вкладка 1 -->
                    <v-tab-item>
                        <v-card class="pa-3" min-height="230" flat>
                            <v-card-title class="text-h6">
                                {{spec[lang]}}:
                            </v-card-title>
                            <v-chip-group column class="pl-4">
                                <v-chip label color="grey lighten-3" large > 
                                    <v-icon left>mdi-weight-gram</v-icon>
                                    {{mass[lang]}}: 
                                    <span v-if="dialogContent.id != 31"> 14g</span>
                                    <span v-else> 20g</span>
                                </v-chip>
                                <v-chip label color="grey lighten-3" large > 
                                    <v-icon left>mdi-candy-outline</v-icon>
                                    {{quant[lang]}}: 
                                    <span v-if="dialogContent.id != 31"> 50</span>
                                    <span v-else> 60</span>
                                </v-chip>
                                <v-chip label color="grey lighten-3" large > 
                                    <v-icon left>mdi-arrow-left-right</v-icon>
                                    {{wdth[lang]}}: 62mm
                                </v-chip>
                                <v-chip label color="grey lighten-3" large > 
                                    <v-icon left>mdi-arrow-up-down</v-icon>
                                    {{hght[lang]}}: 50mm
                                </v-chip>
                            </v-chip-group>
                            
                           
                        </v-card>
                    </v-tab-item>
                      <!-- Вкладка 2 -->
                    <v-tab-item>
                        <v-card class="pa-3" min-height="230" flat>
                            <v-card-title class="text-h6">
                                {{ingr[lang]}}:
                            </v-card-title>
                            <v-card-text class="text-body-2">
                                {{dialogContent.spec.ingr}}
                            </v-card-text>
                            <v-card-title class="text-h6">
                                {{ntf[lang]}}:
                            </v-card-title>
                            <v-card-text class="text-body-2">
                                 {{dialogContent.spec.facts}}
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Вкладка 3 -->
                    <v-tab-item>
                        <v-card class="pa-3" min-height="230" flat>
                            <v-card-title class="text-h6 mb-0">
                                {{showBox.title[lang]}}    
                            </v-card-title>
                            <v-data-table
                                disable-sort
                                :headers="headers[lang]"
                                :items="getShowBox(showBox.items, dialogContent.id)"
                                hide-default-footer>
                            </v-data-table>
                            <!--  -->
                            <v-card-title class="text-h6 mb-0">
                                {{transportBox.title[lang]}}    
                            </v-card-title>
                            <v-data-table
                                disable-sort
                                :headers="headers[lang]"
                                :items="getShowBox(transportBox.items, dialogContent.id)"
                                hide-default-footer>
                            </v-data-table>
                            <!--  -->
                            <v-card-title class="text-h6 mb-0">
                                {{pallet.title[lang]}}    
                            </v-card-title>
                            <v-data-table
                                disable-sort
                                :headers="headersPallet[lang]"
                                :items="getShowBox(pallet.items, dialogContent.id)"
                                hide-default-footer>
                            </v-data-table>
                         </v-card>
                    </v-tab-item>

                  
                </v-tabs-items>

                

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="closeDialog($emit)" large>
                       {{close[lang]}}
                    </v-btn>
                    <v-btn  text @click="closeDialog($emit), $vuetify.goTo(target, options)" color="#9E854D" large>
                        {{order[lang]}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>
import DialogSlider from '../components/DialogSlider.vue'
import {mapGetters} from 'vuex'

export default {
    components: {DialogSlider},
    props: {
        dialogContent: {
            required: true,
            type: Object
        },
        dialog: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        ...mapGetters({
            lang: "lang/getLang",
            showBox: "tables/getShowBox",
            transportBox: "tables/getTransportBox",
            pallet: "tables/getPallet"
        })
    },
    data(){
        return{
            headers: {
                en: [
                    { text: 'Name', value: 'name' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Size, sm', value: 'size' },
                    { text: 'Gross weight, kg', value: 'weight' },
                    { text: 'Barcode', value: 'barcode'}
                ],
                de: [
                    { text: 'Name', value: 'name' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Size, sm', value: 'size' },
                    { text: 'Gross weight, kg', value: 'weight' },
                    { text: 'Barcode', value: 'barcode'}
                ],
                ru: [
                    { text: 'Наименование', value: 'name' },
                    { text: 'Кол-во товара, шт.', value: 'quantity' },
                    { text: 'Размеры, см', value: 'size' },
                    { text: 'Вес БРУТТО, кг', value: 'weight' },
                    { text: 'Штрих-код', value: 'barcode'}
                ]
            },
            headersPallet: {
                en: [
                    { text: 'Name', value: 'name' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Box quantity', value: 'box' },
                    { text: 'Size, sm', value: 'size'},
                    { text: 'Gross weight, kg', value: 'weight' }
                ],
                de: [
                    { text: 'Name', value: 'name' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Box quantity', value: 'box' },
                    { text: 'Size, sm', value: 'size'},
                    { text: 'Gross weight, kg', value: 'weight' }
                ],
                ru: [
                    { text: 'Наименование', value: 'name' },
                    { text: 'Кол-во товара, шт.', value: 'quantity' },
                    { text: 'Кол-во коробок', value: 'box' },
                    { text: 'Размеры, см', value: 'size'},
                    { text: 'Вес БРУТТО, кг', value: 'weight' }
                ],
            },
            target: '#contacts',
            options: {
                duration: 300,
                offset: 0,
                easing: 'easeInOutCubic',
            },
            order: {
                en: 'Make order',
                de: 'Bestellen',
                ru: 'Заказать',
            },
            close: {
                en: 'Close',
                de: 'Schließen',
                ru: 'Закрыть',
            },
            taste: {
                en: 'Taste',
                de: 'Geschmack',
                ru: 'Вкус',
            },
            hght: {
                en: 'Height',
                de: 'Höhe',
                ru: 'Высота',
            },
            mass: {
                en: 'Mass',
                de: 'Masse',
                ru: 'Масса',
            },
            quant: {
                en: 'Quantity',
                de: 'Menge',
                ru: 'Количество',
            },
            wdth: {
                en: 'Width',
                de: 'Breite',
                ru: 'Ширина',
            },
            spec: {
                en: 'Specifications',
                de: 'Spezifikationen',
                ru: 'Спецификация',
            },
            ingr: {
                en: 'Ingredients',
                de: 'Zutaten',
                ru: 'Ингридиенты',
            },
            ntf: {
                en: 'Nutrition facts (100g)',
                de: 'Nährwerte (100g)',
                ru: 'Питательные вещества (100g)',
            },
            tab: null,
            tabs: [
                {
                    en: 'Specifications',
                    de: 'Spezifikationen',
                    ru: 'Спецификация',
                },
                {
                    en: 'Description',
                    de: 'Beschreibung',
                    ru: 'Описание',
                },
                {
                    en: 'Logistics',
                    de: 'Logistik',
                    ru: 'Логистика',
                }
            ]
        }
    },
    methods: {
        closeDialog(){
            this.$emit('closeDialog')
        },
        getShowBox(arr ,id){
           let result = arr.find(item =>{
               return item.id == id
           })

           let tableArr = [];
           tableArr.push(result)
           return tableArr

        }
    }
}
</script>