import axios from 'axios'
import {API} from '../config'

const ui = {
	namespaced: true,
	state: {
		mainSlides: []
  	},
	mutations: {
		SET_SLIDER(state, mainSlides){
			state.mainSlides = mainSlides
		}
	},
	actions: {
		loadSlider({commit}){
			axios.get(`${API}get/slides`)
			.then(res =>{
				commit("SET_SLIDER", res.data.data)
			})
		}
	},
	getters: {
        getMainSlides(state){
			return state.mainSlides
		}
	}
}

export default ui


