<template>
    <v-main app class="py-8">
        <Preloader v-if="!singleCategory" />
        <div v-if="id !== 'energon-energy'">
            <Particles id="tsparticles" :options="particlesOptions"/>
            <Inner />
            <Goods :goods="singleCategory[lang]" v-if="singleCategory" />
        </div>

        <div v-else>
            <!-- <h2>ENERGY COMPONENT</h2> -->
            <Energy />
            <Goods :goods="singleCategory[lang]" v-if="singleCategory" />
        </div>

        <!-- <TableGood v-if="$vuetify.breakpoint.mdAndUp" /> -->

    </v-main>
</template>



<script>
import Inner from '../components/Inner.vue'
import Goods from '../components/Goods.vue'
import Preloader from '../components/Preloader.vue'
import Energy from '../components/Energy.vue'
// import TableGood from '../components/Table.vue'
import { mapGetters } from 'vuex'

export default {
    props: ["id"],
    computed: {
        ...mapGetters({
            singleCategory: "goods/getSingleCategory",
            lang: "lang/getLang"
        })
    },
    created(){
        this.$store.dispatch('goods/loadCategory', this.id)
    },
    components: {Inner, Goods, Preloader, Energy},
    data(){
        return{
            particlesOptions: {
                background: {
                    color: {
                        value: '#fff'
                    }
                },
                particles: {
        number: { value: 160, density: { enable: true, value_area: 800 } },
        color: { value: "#e5e4e4" },
        shape: { type: "circle", stroke: { width: 0, color: "#000000" }, polygon: { nb_sides: 5 }, image: { src: "img/github.svg", width: 100, height: 100 } },
        opacity: { value: 1, random: false, anim: { enable: false, speed: 2.9234779642848423, opacity_min: 1, sync: false } },
        size: { value: 3, random: true, anim: { enable: false, speed: 4, size_min: 0.3, sync: false } },
        line_linked: { enable: false, distance: 150, color: "#ffffff", opacity: 0.4, width: 1 },
        move: { enable: true, speed: 1, direction: "none", random: true, straight: false, out_mode: "out", bounce: false, attract: { enable: false, rotateX: 600, rotateY: 600 } },
    },
    interactivity: {
        detect_on: "canvas",
        events: { onhover: { enable: true, mode: "bubble" }, onclick: { enable: false, mode: "repulse" }, resize: true },
        modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 250, size: 0, duration: 2, opacity: 0, speed: 3 },
            repulse: { distance: 400, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
        },
    },
    detectRetina: true
            }
        }
    }
}
</script>



