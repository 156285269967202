<template>
  <div>
    <v-main app class="py-0">
      <section id="inner">
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" lg="6">
              <h2 class="mb-2 wow myFadeup">
                Цели
              </h2>
            </v-col>
            <v-col cols="12" xs="12" lg="6" class="text-right hidden-sm-and-down">
              <router-link to="/" tag="a">
                <v-btn elevation="0" rounded class="mt-0">
                  <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                  {{btn[lang]}}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="pb-16">
        <v-container>
          <v-row>
            <v-col cols="12" lg="12">
              <div class="text-body-1 font-weight-regular  mt-2 wow myFadeup">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>
                          Наименование цели
                        </th>
                        <th>
                          Целевое значение
                        </th>
                        <th>
                          Сроки выполнения
                        </th>
                        <th>
                          Ответственный
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Выполнение производственного плана</td>
                        <td>100%</td>
                        <td>В течение года</td>
                        <td>Начальник производства</td>
                      </tr>
                      <tr>
                        <td>Увеличить производительность линии штамповки</td>
                        <td>Выполнение графиков предупредительного технического обслуживания с показателем 100%.
Сокращение времени простоев оборудования в два раза.</td>
                        <td>3 квартал года</td>
                        <td>Ведущий технический специалист</td>
                      </tr>
                      <tr>
                        <td>Количество полученных рекламаций от потребителя</td>
                        <td>Не более 2 обращений в месяц</td>
                        <td>В течение года</td>
                        <td>Химик-технолог</td>
                      </tr>
                      <tr>
                        <td>Повысить квалификацию персонала в том числе в области качества и безопасности пищевой продукции</td>
                        <td>Направить на обучение по повышению квалификации сотрудников, в количестве 2 человек.
Провести аттестацию персонала на соответствие должности-5 человек</td>
                        <td>В течение года</td>
                        <td>Специалист отдела кадров</td>
                      </tr>
                      <tr>
                        <td>Закупка и пусконаладка производственного оборудования.</td>
                        <td>Реализовать проекты по закупке новых производственных линий штамповки и фасовки</td>
                        <td>Ноябрь</td>
                        <td>Ведущий технический специалист</td>
                      </tr>
                      <tr>
                        <td>Приобретение нового оборудования цеха таблетирования</td>
                        <td>Реализовать проект по закупке нового таблетпресса и периферийного оборудования для производства конфеты</td>
                        <td>4 квартал года</td>
                        <td>Ведущий технический специалист</td>
                      </tr>
                      <tr>
                        <td>Увеличить объем реализации выпускаемой продукции</td>
                        <td>+30% по сравнению с 2022г</td>
                        <td>3 квартал года</td>
                        <td>Менеджер по работе с ключевыми клиентами</td>
                      </tr>
                      <tr>
                        <td>Обеспечить качество закупаемых сырья и материалов</td>
                        <td>100% соответствия закупок требованиям предприятия.</td>
                        <td>В течение года</td>
                        <td>Менеджер по закупкам</td>
                      </tr>
                      <tr>
                        <td>Обеспечить стабильный уровень качества и безопасности продукции</td>
                        <td>Количество протоколов исследований продукции на соответствие НД 100% подтверждения</td>
                        <td>В течение года</td>
                        <td>Химик технолог</td>
                      </tr>
                      <tr>
                        <td>Снизить потери по жести</td>
                        <td>Не более 3%</td>
                        <td>В течение года</td>
                        <td>Ведущий технический специалист</td>
                      </tr>
                    </tbody>




                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    data() {
      return {
        // mainTitle: {
        //     en: 'Privacy policy',
        //     de: 'Datenschutz-Bestimmungen',
        //     ru: 'ЙЦе',
        // },
        btn: {
            en: 'Main page',
            de: 'Hauptseite',
            ru: 'Главная страница',
        }
      }
    }
  }
</script>

<style scoped>
tbody tr{
  padding: 10px 0 !important;
}
</style>