<template>
    <div @click.stop class="dialog-slider">
        <swiper ref="slDialog" :options="swiperOptions" >
            <swiper-slide v-for="(img, index) in images" :key="index">
                <div class="slide-itm text-center">
                    <img :src="img" height="250">
                </div>
            </swiper-slide>
        </swiper>
            <v-btn fab dark small id="buttonPrev1"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
            <v-btn fab dark small id="buttonNext1"><v-icon dark>mdi-arrow-right</v-icon></v-btn>
            <div class="swiper-pagination swiper-pagination1 mt-5"></div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data(){
        return{
            swiperOptions: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    draggable: true,
                    touchRatio: 1,
                    centeredSlides: true,
                    loop: true,    
                    infinite: true,
                    threshold: 50,
                    speed: 400,
                    pagination: {
                        el: ".swiper-pagination1",
                        dynamicBullets: false,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: "#buttonNext1",
                        prevEl: "#buttonPrev1",
                    },
                },
        }
    }
}
</script>


<style scoped>
#buttonPrev1{
    position: absolute;
    left: 15px;
    z-index: 95;
    top: 40%;
}
#buttonNext1{
    position: absolute;
    right: 15px;
    z-index: 95;
    top: 40%;
}
</style>