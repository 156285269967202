<template>
    <v-card class="map-sidebar mx-auto" max-width="500" elevation="10" v-if="$vuetify.breakpoint.lgAndUp">
        <v-toolbar color="#000" dark>

            <v-toolbar-title class="font-weight-bold">
                 <v-icon class="mr-2">mdi-compass-outline</v-icon>
                 <span v-html="title[lang]"></span>
            </v-toolbar-title>

        </v-toolbar>

        <v-list two-line max-height="500" class="scroll">
            <v-list-item-group dense v-model="selected" active-class="orange--text lighten-1" @change="changeLocation">
                <template v-for="(item, index) in items">
                    <v-list-item :key="item.title">
                        <template v-slot:default="{ active }">
                            <v-list-item-content>
                                
                                <v-list-item-title class="d-flex align-center font-weight-medium" color="black">
                                    <img :src="item.img" class="mr-2" height="20">
                                    {{item.title}}
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <!-- <v-list-item-action-text>
                                    {{item.distributors}} {{distr[lang]}}
                                </v-list-item-action-text> -->

                                <v-icon v-if="!active" color="grey lighten-1">
                                    mdi-map-marker-outline
                                </v-icon>

                                <v-icon v-else color="orange">
                                    mdi-map-marker
                                </v-icon>
                            </v-list-item-action>
                        </template>
                    </v-list-item>

                    <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    methods: {
        changeLocation(){
           this.$emit('changeLocation', this.items[this.selected].coords)
        }
    },
    data(){
        return{
            distr: {
                en: 'distributors',
                de: 'händler'
            },
            title: {
                en: '<span class="font-weight-light">Sales geography</span>',
                de: '<span class="font-weight-light">Umsatz Geografie</span>',
                ru: '<span class="font-weight-light">География продаж</span>'
            },
            selected: 0,
            items: [
                {
                    distributors: 30,
                    title: 'Russian federation',
                    img: require('../assets/img/ru.png'),
                    coords: {lat: 55.78977538604724, lng: 37.60240872612117},
                
                },
                {
                    distributors: 5,
                    title: 'Belarus',
                    img: require('../assets/img/bel.png'),
                    coords: {lat: 53.395724, lng: 28.064281},
                },
                {
                    distributors: 10,
                    title: 'Kazakhstan',
                    img: require('../assets/img/kz.png'),
                    coords: {lat: 48.622118, lng: 66.853642}
                },
                {
                    distributors: 10,
                    title: 'Georgia',
                    img: require('../assets/img/ge.svg'),
                    coords: {lat: 41.693081, lng: 44.801559}
                },
                {
                    distributors: 10,
                    title: 'Iran',
                    img: require('../assets/img/ir.svg'),
                    coords: {lat: 35.661703, lng: 51.367734}
                },
                {
                    distributors: 10,
                    title: 'China',
                    img: require('../assets/img/ch.png'),
                    coords: {lat: 36.209204, lng: 103.111201}
                },
                {
                    distributors: 10,
                    title: 'Armenia',
                    img: require('../assets/img/am.png'),
                    coords: {lat: 40.177628, lng: 44.512546}
                },
                {
                    distributors: 10,
                    title: 'Azerbaijan',
                    img: require('../assets/img/az.png'),
                    coords: {lat: 40.368913, lng: 49.835029}
                },
                {
                    distributors: 10,
                    title: 'Mongolia',
                    img: require('../assets/img/mn.png'),
                    coords: {lat: 47.918501, lng: 106.917602}
                },
                {
                    distributors: 10,
                    title: 'Vietnam',
                    img: require('../assets/img/vm.png'),
                    coords: {lat: 16.831510, lng: 106.891652}
                },

            ],
        }
    }
}
</script>