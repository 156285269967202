<template>
    <section id="inner">
            <v-container>
                <v-row>
                    <v-col cols="12" xs="12" lg="6">
                        <h2 class="mb-2 wow myFadeup" v-html="title[lang]">
                        </h2>
                        <div class="text-h6 font-weight-regular mb-4 wow myFadeup" >
                            {{subtitle[lang]}}
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" lg="6" class="text-right hidden-sm-and-down">
                        <router-link to="/collection" tag="a">
                            <v-btn elevation="0" rounded class="mt-16">
                                <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                                {{btn[lang]}}
                            </v-btn>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return{
            title: {
                en:  '<span class="font-weight-light">Collection<br> of refreshing flavors</span>',
                de: '<span class="font-weight-light">Kollektion<br> erfrischender Aromen</span>',
                ru:  '<span class="font-weight-light">Коллекция<br>освежающих вкусов</span>',
            },
            subtitle: {
                en: '',
                de: '',
                ru: ''
            },
            btn: {
                en: 'Our brands',
                de: 'Unsere Marken',
                ru: 'Коллекции',
            }
        }
    }
}
</script>