<template>
<div>
    <section id="contacts">
        <v-container>
            <v-row>
                <v-col lg="12">
                    <h2 class="mb-10 wow myFadeup" style="line-height:50px;" v-html="title[lang]">
                    </h2>
                </v-col>

                <v-col cols="12" lg="6" xs="12">
                    
                    <v-form ref="form" @submit.prevent="submit">
                        <v-row>
                            <v-col cols="12" lg="6" xs="12">
                                <v-text-field :label="nameLabel[lang]" class="my-0" hide-details color="#9E854D"
                                v-model="cname" ref="cname" 
                                :rules="[() => !!cname || 'This field is required']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6" xs="12">
                                <v-text-field :label="emailLabel[lang]" class="my-0" hide-details color="#9E854D"
                                v-model="email"
                                :rules="[() => !!email || 'This field is required']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6" xs="12">
                                <v-text-field :label="telLabel[lang]" class="my-0" hide-details color="#9E854D"
                                v-model="tel"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6" xs="12">
                                <v-text-field :label="themeLabel[lang]" class="my-0" hide-details color="#9E854D"
                                v-model="subj"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="12" xs="12">
                            <v-textarea :label="messageLabel[lang]" no-resize  class="my-0" hide-details color="#9E854D" rows="2"
                            v-model="message"></v-textarea>
                            </v-col>
                            <v-col cols="12" lg="12" xs="12">
                                <v-btn width="170px" x-large dark class="font-weight-bold" @click="submit">
                                    {{btn[lang]}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    
                </v-col>


                <v-col lg="6" v-if="$vuetify.breakpoint.lgOnly">
                    <img src="../assets/img/openbin.png" style="width: 100%;margin-top:-100px;">
                </v-col>


            
            </v-row>
        </v-container>
    </section>

    <section id="footer">
        <v-container>
            <v-row>
                <v-col lg="12" class="mt-5">
                    <v-row>
                        <v-col cols="12" lg="3" xs="12">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{general.title[lang]}}</div>
                            <div class="text-body-1 mt-2 font-weight-bold mb-2">{{general.subtitle[lang]}}</div>
                            <a class="text-body-1" style="color:#9E854D !important;"
                            href="https://wa.me/79219037613" target="_blank">
                                <i class="fab fah fa-lg fa-whatsapp"></i>
                                {{general.whatsapp[lang]}}
                            </a>
                        </v-col>
                        <v-col cols="12" lg="3" xs="12">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{business[lang]}}</div>
                            <div class="text-body-1 mt-2 font-weight-bold mb-2">
                                <a href="mailto:kd@energongroup.ru" > kd@energongroup.ru</a>
                            </div>
                              <a href="tel:+78129037613" class="text-body-1" style="color:#9E854D !important;">+7 812 903 76 13</a>
                        </v-col>
                        <v-col cols="12" lg="3" xs="12">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{office.title[lang]}}</div>
                            <div class="text-body-1 mt-2 font-weight-bold mb-2">TD ENERGON RUS</div>
                            <div class="text-body-2">{{office.adress[lang]}}</div>
                        </v-col>
                        <v-col cols="12" lg="3" xs="12">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{factory.title[lang]}}</div>
                            <div class="text-body-1 mt-2 font-weight-bold mb-2">TD ENERGON RUS</div>
                            <div class="text-body-2">{{factory.adress[lang]}}</div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col lg="12" class="mt-1">
                    <v-row>
                        <v-col lg="3">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{support[lang]}}</div>
                            <a href="https://crplo.ru/export" target="blank_">
                                <v-img src="../assets/img/support.png" contain class="my-5" width="150px"></v-img>
                            </a>
                        </v-col>
                        <v-col lg="3">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{develop[lang]}}</div>
                            <a href="https://webink.site/" target="blank_">
                                <v-img src="../assets/img/webink.png" contain class="my-5" width="150px"></v-img>
                            </a>
                        </v-col>
                        <v-col lg="3">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{partner[lang]}}</div>
                            <a href="http://energongroup.ru/" target="blank_">
                                <!-- <v-img src="../assets/img/energon.jpeg" contain class="my-5" width="300px"></v-img> -->
                                <img src="../assets/img/energong.png" style="height:70px;">
                            </a>
                        </v-col>
                        <v-col lg="3">
                            <div class="text-subtitle-2 text-uppercase grey--text">{{cert[lang]}}</div>
                            <a href="https://www.fssc22000.com/" target="blank_">
                                <!-- <v-img src="../assets/img/energon.jpeg" contain class="my-5" width="300px"></v-img> -->
                                <img src="../assets/img/fscc.svg" style="height:30px;" class="mt-5">
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    methods: {
        submit(){
            this.$refs.form.validate()
            if(!this.cname || !this.email){
                return
            }

            let emailBody = {
				cname: this.cname,
				email: this.email,
				phone: this.tel,
                theme: this.subj,
                message: this.message
			}

			var form = new FormData();

			for (var field in emailBody){
				form.append(field, emailBody[field]);
			};

            axios
            	.post('https://scandicmints.com/wp-json/contact-form-7/v1/contact-forms/11/feedback/', form)
                .then((response) => {
					 alert('Your email was submited')
                })
                .catch((error) => {
                    alert('Opps! Error? Please try again.')
                });
        }
    },
    data(){
        return{
            cert: {
                en: 'Certification',
                de: 'Zertifizierung',
                ru: 'Сертификация'
            },
            cname: '',
            email: '',
            tel: '',
            subj: '',
            message: '',
            support: {
                en: 'with the support of',
                de: 'mit der Unterstützung von',
                ru: 'при поддержке',
            },
            develop: {
                en: 'developed in',
                de: 'entwickelt in',
                ru: 'разработано в'
                
            },
            partner: {
                en: 'in partnerships with',
                de: 'in Partnerschaften mit',
                ru: 'Партнер',
            },
            nameLabel:{en: 'Name', de: 'Dein Name',ru: 'Имя'},
            emailLabel:{en: 'E-mail', de: 'Deine E-Mail',ru: 'E-mail'},
            telLabel:{en: 'Phone', de: 'Dein Telefon',ru: 'Телефон'},
            themeLabel:{en: 'Subject', de: 'Gegenstand',ru: 'Тема'},
            messageLabel:{en: 'Message', de: 'Nachricht',ru: 'Сообщение'},
            btn: {
                en: 'Submit form',
                de: 'Formular senden',
                ru: 'Отправить',
            },
            office: {
                title: {
                    en: 'Office',
                    de: 'Sekretariat',
                    ru: 'Офис'
                },
                adress: {
                    en: '188300, Russia, Gatchina, 7-th Army str, 24-40',
                    de: ' 188300, Russland, Gatchina, 7 Armee Straße, 24-40',
                    ru: '188300, Россия, Гатчина, ул. 7 Армии, 24-40',
                }
            },
            factory: {
                title: {
                    en: 'Factory',
                    de: 'Fabrik',
                    ru: 'Фабрика'
                },
                adress: {
                    en: '188360, Russia, Voyskovitsy, Industrial area 2, 2 building 7',
                    de: ' 188360, Russland, Voyskovitsy, Industriegebiet 2, 2 Gebäude 7',
                    ru: '188360, Россия, Войсковицы, Индустриальная 2, 2 строение 7',
                }
            },
            business: {
                en: 'New business',
                de: 'Neues Geschäft',
                ru: 'Контакты',
            },
            general: {
                title: {
                    en: 'General inquiri',
                    de: 'Allgemeine Anfrage',
                    ru: 'Обратная связь',
                },
                subtitle: {
                    en: 'Quick chat',
                    de: 'Schneller Chat',
                    ru: 'Чат',
                },
                whatsapp: {
                    en: 'Type on Whatsapp',
                    de: 'Tippe auf Whatsapp',
                    ru: 'Написать в Whatsapp',
                }
            },
            title: {
                en: '<span class="font-weight-light">There are still questions?<br>Drop us a line</span>',
                de: '<span class="font-weight-light">Es gibt noch Fragen?<br> Schreiben Sie uns</span>',
                ru: '<span class="font-weight-light">Остались вопросы?<br>Напишите нам</span>',
            }
        }
    },
}
</script>