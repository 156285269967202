<template>
  <div>
    <v-main app class="py-8">
      <Particles id="tsparticles" :options="particlesOptions" />
      <section id="inner">
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" lg="6">
              <h2 class="mb-2 wow myFadeup" v-html="mainTitle[lang]">
              </h2>
            </v-col>
            <v-col cols="12" xs="12" lg="6" class="text-right hidden-sm-and-down">
              <router-link to="/" tag="a">
                <v-btn elevation="0" rounded class="mt-16">
                  <v-icon x-small class="mr-2">mdi-arrow-left</v-icon>
                  {{btn[lang]}}
                </v-btn>
              </router-link>
            </v-col>

            <!-- <v-col cols="12" xs="6" lg="3" v-for="(fact, index) in facts" :key="index">
              <v-card-title class="text-h3 pl-0 font-weight-bold">
                {{fact.num}}
              </v-card-title>
              <v-card-text class="text-body-1 pl-0">
                {{fact.descr[lang]}}
              </v-card-text>
            </v-col> -->

          </v-row>
        </v-container>
      </section>

      <section class="py-8">
        <v-container>
          <v-row>
            <v-col cols="12" lg="12">
              <div class="main-video">
                <div class="video-box-blur"></div>
                <div class="video-box">

                  <video src="https://scandicmints.com/wp-content/uploads/2020/11/all-.mp4" autoplay="autoplay"
                    loop="loop" muted="muted" playsinline="playsinline"></video>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="py-8">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <img src="../assets/img/factory1.png" style="width:100%;">
            </v-col>
            <v-col cols="12" lg="6">
              <h2 class="wow myFadeup">{{videoDescr.title[lang]}}</h2>
              <div class="text-h6 font-weight-regular  mt-2 wow myFadeup">
                {{videoDescr.descr[lang]}}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="py-8">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" order-lg="2">
               <img src="../assets/img/factory2.png" style="width:100%;">
            </v-col>
            <v-col cols="12" lg="6" order-lg="1">
              <img src="../assets/img/fscc.svg" style="height:60px;" class="mb-3">
              <div class="text-h6 font-weight-regular  mt-2 wow myFadeup">
                {{videoDescr.descr2[lang]}}
              </div>
              <router-link tag="a" to="/environment">
                <v-btn x-large dark class="font-weight-bold mt-6 wow fadeIn" >
                  {{env[lang]}}
                </v-btn>
              </router-link>
            </v-col>
            
          </v-row>
        </v-container>
      </section>

<!-- 
      <section class="py-16">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-img src="../assets/img/openbin.png" width="100%"
                class="wow myFadeup">
              </v-img>
            </v-col>
            <v-col cols="12" lg="6">
              <h1 class="mb-8 wow myFadeup" v-html="lolipop.title[lang]">
              </h1>
              <div class="text-h6 font-weight-regular mb-4 wow myFadeup" v-html="lolipop.descr[lang]">
              </div>
              <router-link tag="a" to="/collection/original">
                <v-btn x-large dark class="font-weight-bold mt-10 wow fadeIn" >
                  {{lolipop.btn[lang]}}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section> -->





    </v-main>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    data() {
      return {
        env: {
          en: 'Environment',
          de: 'Umfeld',
          ru: 'Окружающая среда',
        },
        lolipop: {
          title: {
            en: '<span class="font-weight-light">Choose the taste <br>to brighten<br> your day </span>',
            de: '<span class="font-weight-light">Wählen Sie Ihren Lieblingsgeschmack <br></span> aufhellen<br> dein Tag',
            ru: '<span class="font-weight-light">Choose the taste <br>to brighten<br> your day </span>',
          },
          descr: {
            en: 'Healthy ingredients in each lozenge.',
            de: 'Gesunde Inhaltsstoffe in jeder Lutschtablette.',
            ru: 'Healthy ingredients in each lozenge.',
          },
          btn: {
            en: 'Find your taste',
            de: 'Finden Sie Ihren Geschmack',
            ru: 'Find your taste',
          }
        },
        videoDescr: {
          title: {
            en: 'First-rate factory, focused on people oriented approach.',
            de: 'Erstklassige Fabrik, die sich auf den menschenorientierten Ansatz konzentriert.',
            ru: 'Первоклассная фабрика, ориентированная на людей.',
          },
          descr: {
            en: 'The Energon Group was established in 2011. lt specializes оп the production of healthy food products. The bases of our assortment are sugar-free candies ТМ SCANDIC. ',
            de: 'Die Energon Group wurde 2011 gegrundet und ist auf die Herstellung von gesunden Lebensmitteln spezialisiert. Die Basis unseres Sortiments bilden SuBigkeiten ohne Zucker ТМ SCANDIC.',
            ru: 'Группа компаний «Энергон» создана в 2011 году. Она специализируется на производстве продуктов здорового питания. Основу нашего ассортимента составляют конфеты без сахара ТМ SCANDIC.'
          },
          descr2: {
            en: 'We implement а complete production circle of this product output. Our production complex is located in Gatchina, Russia and is а unified automated line which does not have any analogues not only in Russia or CIS but also in Europe. The production is implemented in the strict accordance with safety regulations and all necessary standards. ',
            de: 'AuBerdem fuhren wir einen vollstandigen Produktionszyklus fur die Herstellung unserer Produkte durch. Unser Produktionskomplex befindet sich in der Region Leningrad und ist eine einzige automatisierte Linie, die nicht nur in Russland und den GUS-Staaten, sondern auch in Europa einzigartig ist. Die Produkte werden unter strikter Einhaltung von Sicherheits- und Hygienestandards hergestellt. ',
            ru: 'Мы реализуем полный производственный цикл выпуска данной продукции. Наш производственный комплекс расположен в Гатчине и представляет собой единую автоматизированную линию, не имеющую аналогов не только в России или СНГ, но и в Европе. Производство осуществляется в строгом соответствии с правилами техники безопасности и всеми необходимыми стандартами.'
          }
        },
        facts: [
          {
            num: '1',
            descr: {
              en: 'The unique factory with new cycle production of sugar free mints in tins was founded in Russia in 2020 ',
              de: 'Die einzigartige Fabrik mit neuer Zyklusproduktion von zuckerfreien Pfefferminzbonbons in Dosen wurde 2020 in Russland gegründet',
              ru: 'The unique factory with new cycle production of sugar free mints in tins was founded in Russia in 2020 ',
            }
          },
          {
            num: '10',
            descr: {
              en: 'Years of experience on the confectionary markets of Russia and European countries',
              de: 'Langjährige Erfahrung auf den Süßwarenmärkten Russlands und europäischer Länder',
              ru: 'Years of experience on the confectionary markets of Russia and European countries',
            }
          },
          {
            num: '2011',
            descr: {
              en: 'The Energon Group was established',
              de: 'Die Energon-Gruppe wurde gegründet',
              ru: 'The Energon Group was established',
            }
          },
          {
            num: '6',
            descr: {
              en: 'Unique flavors',
              de: 'Einzigartige Aromen',
              ru: 'Unique flavors',
            }
          },
        ],
        btn: {
          en: 'Main page',
          de: 'Hauptseite',
          ru: 'Главная страница',
        },
        mainTitle: {
          en: '<span class="font-weight-light">Our Story</span> <br>Scandic Mints',
          de: '<span class="font-weight-light">Unsere Geschichte</span> <br>Scandic Mints',
          ru: '<span class="font-weight-light">История</span> <br>Scandic Mints',
        },
        subtitle: {
          en: 'A creative production built to lift-off fresh mints market <br><strong>to a new level</strong>',
          de: 'Eine kreative Produktion, die entwickelt wurde, um den Markt <br > für frische Pfefferminzbonbons anzukurbeln<br> auf ein <strong>neues Leve</strong>',
          ru: 'A creative production built to lift-off fresh mints market <br><strong>to a new level</strong>',
        },
        particlesOptions: {
          background: {
            color: {
              value: '#fff'
            }
          },
          particles: {
            number: {
              value: 160,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: "#e5e4e4"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 1,
              random: false,
              anim: {
                enable: false,
                speed: 2.9234779642848423,
                opacity_min: 1,
                sync: false
              }
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600
              }
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bubble"
              },
              onclick: {
                enable: false,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3
              },
              repulse: {
                distance: 400,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              },
            },
          },
          detectRetina: true
        }
      }
    }
  }
</script>