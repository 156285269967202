const tables = {
	namespaced: true,
	state: {
        pallet: {
            title: {
                en: 'Pallet',
                de: 'Pallet',
                ru: 'Паллет',
            },
            items: [
                {
                    name: 'Citrus mix',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 23
                },
                {
                    name: 'Spicy apple',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 26
                },
                {
                    name: 'Arctic mint',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 24
                },
                {
                    name: 'Wild berries',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 25
                },
                {
                    name: 'Spicy cola',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 46
                },
                {
                    name: 'Energy original',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 31
                },
                {
                    name: 'Mango shake',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 148
                },
                {
                    name: 'Cherry mint',
                    quantity: '17280',
                    box: '80',
                    size: '1200*800*1600',
                    weight: 600,
                    id: 153
                },
            ],
        },
        transportBox: {
            title: {
                en: 'Transport box',
                de: 'Transport box',
                ru: 'Транспортный короб',
            },
            items: [
                {
                    name: 'Citrus mix',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603738769940,
                    id: 23
                },
                {
                    name: 'Spicy apple',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603738769964,
                    id: 26
                },
                {
                    name: 'Arctic mint',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603738769902,
                    id: 24
                },
                {
                    name: 'Wild berries',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603738769926,
                    id: 25
                },
                {
                    name: 'Spicy cola',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603766425511,
                    id: 46
                },
                {
                    name: 'Energy original',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603766425931,
                    id: 31
                },
                {
                    name: 'Mango shake',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603766426075,
                    id: 148
                },
                {
                    name: 'Cherry mint',
                    quantity: '216',
                    size: '32*26,5*18',
                    weight: '7,2',
                    barcode: 4603766426105,
                    id: 153
                },
            ]
        },
        tableShowBox:  {
                title: {
                    en: 'Show box',
                    de: 'Show box',
                    ru: 'Шоу-бокс',
                },
                items: [
                    {
                        name: 'Citrus mix',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603738769933,
                        id: 23
                    },
                    {
                        name: 'Spicy apple',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603738769957,
                        id: 26
                    },
                    {
                        name: 'Arctic mint',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603738769896,
                        id: 24
                    },
                    {
                        name: 'Wild berries',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603738769919,
                        id: 25
                    },
                    {
                        name: 'Spicy cola',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603766425511,
                        id: 46
                    },
                    {
                        name: 'Energy original',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603766425931,
                        id: 31
                    },
                    {
                        name: 'Mango shake',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603766426075,
                        id: 148
                    },
                    {
                        name: 'Cherry mint',
                        quantity: '18',
                        size: '15*12,3*5,6',
                        weight: '0,6',
                        barcode: 4603766426105,
                        id: 153
                    },
                ]
            },
  	},
	mutations: {

	},
	actions: {

	},
	getters: {
        getShowBox(state){
            return state.tableShowBox
        },
        getTransportBox(state){
            return state.transportBox
        },
        getPallet(state){
            return state.pallet
        }
	}
}

export default tables


