<template>
    <section id="mainSlider" class="py-10">
        <!-- <pre>{{mainSlides}}</pre> -->
        <v-btn fab dark id="buttonPrev"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
            <v-btn fab dark id="buttonNext"><v-icon dark>mdi-arrow-right</v-icon></v-btn>
        <swiper ref="slName" :options="swiperOptions" v-if="mainSlides.length">
            <swiper-slide v-for="(item, index) in mainSlides" :key="index">
                <router-link tag="a" :to="`/collection/original/#${item.product}`">
                    <div class="slider-img" :style="{'background-image': 'url(' + item.mainImage + ')'}"></div>
                </router-link>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper-pagination2 mt-5"></div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            mainSlides: "ui/getMainSlides"
        })
    },
    data(){
        return{
            swiperOptions: {
                    draggable: true,
                    touchRatio: 1,
                    centeredSlides: true,
                    loop: true,    
                    infinite: true,
                    speed: 400,
                    threshold: 50,
                    pagination: {
                        el: ".swiper-pagination2",
                        dynamicBullets: false,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: "#buttonNext",
                        prevEl: "#buttonPrev",
                    },
                    autoplay: {
                        delay: 3500,
                        disableOnInteraction: false,
                    },
                    breakpoints: {
			            320: {
			              slidesPerView: 1.2,
                          spaceBetween: 4,
			            },
			            768: {
				          slidesPerView: 1.2,
                          spaceBetween: 20,
				        },
				        1150: {
				          slidesPerView: 1.5,
                          spaceBetween: 60,
				        }
			        }
                },
        }
    }
}
</script>

<style scoped>
h2{
    padding-bottom: 56.25%;
}
</style>