<template>
  <div>
    <v-main
      app
      class="py-0"
    >
      <section id="inner">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              lg="6"
            >
              <h2
                class="mb-2 wow myFadeup"
                v-html="mainTitle[lang]"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              lg="6"
              class="text-right hidden-sm-and-down"
            >
              <router-link
                to="/"
                tag="a"
              >
                <v-btn
                  elevation="0"
                  rounded
                  class="mt-0"
                >
                  <v-icon
                    x-small
                    class="mr-2"
                  >
                    mdi-arrow-left
                  </v-icon>
                  {{ btn[lang] }}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </section>

    

      <section class="pb-16">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              lg="12"
            >
              <div
                class="text-body-1 font-weight-regular  mt-2 wow myFadeup"
                v-if="lang != 'ru'"
              >
                The management of TD Energon Rus LLC, being aware of its responsibility to the parties concerned, assumes the following obligations:
                <br><br>
                1. Comply with statutory and regulatory food safety requirements, including agreed consumer food safety requirements
                <br><br>
                2. Increase consumer satisfaction by ensuring compliance with food safety requirements established by law and by administrative authorities and supervisors.
                <br><br>
                3. Comply with the requirements of all state standards, norms and rules applied at the enterprise for organizing and maintaining technological processes for the production of food products, as well as in the field of hygiene, industrial sanitation and labor protection.
                <br><br>
                4. Improve the consumer characteristics of products, ensure a reputation for the manufacture of high-quality, reliable and competitive food products.
                <br><br>
                5. Follow the sequence of stages of technological processes of food production in order to prevent, eliminate or reduce to an acceptable level the hazard that threatens food safety by developing and implementing the activities of the hazard management plan (HACCP / FPOPM plan).
                <br><br>
                6. Ensure compliance with sanitary and hygienic conditions for the maintenance of production premises and equipment necessary for the production of safe products.
                <br><br>
                7. Develop, document, plan, implement, apply, maintain and update a product safety management system aimed at producing food products that are safe for the consumer when consumed as intended, ensure continuous improvement of the FSMS in accordance with ISO 22000-2018, ISO TS 22002-1-2009 and additional requirements of the FSSC 22000 v.5.1 scheme
                <br><br>
                8. Communicate effectively on food safety issues with suppliers, consumers and relevant stakeholders in the food chain. Maintain a high and sufficient level of quality control over the state of raw materials, production processes and finished products.
                <br><br>
                9. Guarantee the operation in accordance with the stated food safety policy and communicate the provisions of this Policy to all employees of the enterprise and maintain it at all levels by developing and reviewing Food Safety Goals and analyzing the results of their achievement, consistent with the context enterprises.
                <br><br>

                <strong>
                  I take responsibility for the implementation of the policy in the field of quality and food safety
                  <br><br>
                  General Director of LLC "TD Energon Rus" Shipulin M.A.
                </strong>
              </div>

              <div
                class="text-body-1 font-weight-regular  mt-2 wow myFadeup"
                v-if="lang == 'ru'"
              >
                Руководство предприятия <strong> ООО «ТД Энергон Рус»</strong>, осознавая свою ответственность перед заинтересованными сторонами, берет на себя выполнение следующих обязательств:
                <br><br>
                1. Соответствовать требованиям в области безопасности пищевых продуктов законодательным и другим обязательным требованиям, включая согласованные требования потребителей в отношении безопасности пищевой продукции
                <br><br>
                2. Повышать степень удовлетворенности потребителей, путем обеспечения соответствия требованиям к безопасности пищевой продукции, установленными законодательством и органами административного управления и надзора.
                <br><br>
                3. Выполнять требования всех применяемых на предприятии государственных стандартов, норм и правил по организации и ведению технологических процессов производства пищевой продукции, а также в области гигиены, промышленной санитарии и охраны труда.
                <br><br>
                4. Совершенствовать потребительские характеристики продукции, обеспечивать репутацию изготовлением качественной, надежной и конкурентоспособной пищевой продукции.
                <br><br>
                5. Соблюдать последовательность этапов технологических процессов производства пищевой продукции с целью предупреждения, устранения или снижения до приемлемого уровня опасности, угрожающей безопасности пищевой продукции путем разработки и реализации мероприятий плана управления опасностями (план ХАССП/ППОПМ).
                <br><br>
                6. Обеспечивать соблюдение санитарно-гигиенических условий содержания производственных помещений и оборудования, необходимых для производства безопасной продукции.
                <br><br>
                7. Поддерживать в рабочем состоянии и актуализировать систему менеджмента безопасности продукции, нацеленную на выпуск пищевой продукции, безопасной для потребителя при употреблении согласно предусмотренному назначению, обеспечивать постоянное улучшение СМБПП в соответствии с ISO 22000-2018, ISO TS 22002-1- 2009 и дополнительным требованиям схемы FSSC 22000 v.5.1
                <br><br>
                8. Осуществлять эффективный обмен информацией по вопросам безопасности пищевой продукции с поставщиками, потребителями и соответствующими заинтересованными сторонами, имеющими отношение к цепи создания данной продукции. Поддерживать на высоком и достаточном уровне качество контроля над состоянием сырья, производственных процессов и готовой продукции.
                <br><br>
                9. Гарантировать работу в соответствии с заявленной политикой в области обеспечения безопасности пищевой продукции и доведение положений настоящей Политики до всех сотрудников предприятия и поддержание ее на всех уровнях путем разработки и пересмотра Целей в области обеспечения безопасности пищевой продукции и анализа результатов их достижения, согласованных с контекстом предприятия.
                <br><br>
                <strong>Принимаю на себя ответственность за реализацию политики в области качества и безопасности пищевой продукции</strong>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    data() {
      return {
        mainTitle: {
            en: 'Privacy policy',
            de: 'Datenschutz-Bestimmungen',
            ru: 'Политика в области качества и безопасности продукции ООО «ТД Энергон Рус» на 2023 год',
        },
        btn: {
            en: 'Main page',
            de: 'Hauptseite',
            ru: 'Главная страница',
        }
      }
    }
  }
</script>
                