<template>
    <section id="partners" class="py-16">
        <v-container>
            <v-row>
                <v-col cols="12" class="text-center">
                    <h2 class="mb-6 wow myFadeup font-weight-light" v-html="title[lang].val"></h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <swiper ref="partnerSlider" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in partners" :key="index">
                            <div class="partner-box" :style="`background-image: url('${item}');`">
                            </div>
                        </swiper-slide>
                    </swiper>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return{
            title: {
                 en: {
                    val: 'Our partners'
                },
                de: {
                    val: 'Unsere Partner'
                },
                ru: {
                    val: 'Наши партнеры'
                }
            },
            partners: [
                'https://testonjob.ru/wp-content/uploads/2016/10/diksi-tests.png',
                'https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Vkusvill_textlogo_2021.svg/2560px-Vkusvill_textlogo_2021.svg.png',
                'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Spar-logo.svg/2560px-Spar-logo.svg.png',
                'https://www.fryazino.info/image/logo/globus.png',
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCCAiAAWfYU-5LErTaPyT3Em4Z_gKWo52VKNa1r5nOyA&s',
                'https://lavkabahusa.ru/templates/lavkabahusa/images/logo.svg',
                'https://butyl.shop/logo.png',
                'http://toplogos.ru/images/logo-bahetle.png',
                'https://www.schelcovo.ru/image/logo/vinlab.png',
                'https://upload.wikimedia.org/wikipedia/ru/f/fa/Av_2021_logo.png',
                'http://i.otzovik.com/objects/b/410000/409542.png',
                'https://static.tildacdn.com/tild3432-3763-4037-b038-333066316536/ios_classic_icon-1.png',
                'https://yoursticker.ru/wp-content/uploads/2021/12/wildberries1.jpg',
                'https://i.otzovik.com/objects/b/1910000/1906546.png',
                'https://habrastorage.org/getpro/moikrug/uploads/company/100/006/036/6/logo/medium_b20da31a5f924ca932dc76aec90c19af.jpg',
                'http://ic.pics.livejournal.com/masterok/50816465/916772/916772_original.jpg',
                'https://habrastorage.org/getpro/moikrug/uploads/company/100/006/157/1/logo/medium_9118e7fbd133441f70791d13a2988073.png',
                'https://stmachinery.ru/upload/iblock/922/92280c994916eb7683a8d96b071be7ae.jpg',
                'https://im.kommersant.ru/gboxtexts/sp_com/logos/018.jpg',
                'https://live.staticflickr.com/3830/8969396545_9cd2512ba7_z.jpg',
                'https://play-lh.googleusercontent.com/savRTq3DBBJbmIP02-g1qFIpeL2jvwPhU4LrqrIOglEsaIseqcf_t7YpeREZiaC1xxc',
                'https://versality.ru/upload/projects/Trassa/logo.png',
                'https://nangs.org/media/k2/items/cache/77535e3d475caea554658904633bd51e_XL.jpg',
                'https://play-lh.googleusercontent.com/aBmKe9DNeSujLb7b-m4lmoFHfxVhW17jSA08KtjZJ9QENDGw65jWMGAHLPge8qe5OPg',
                'https://azskarta.ru/wp-content/uploads/2019/04/gazoyl-200.png',
                'https://www.rogtecmagazine.com/wp-content/uploads/2019/07/1895b70d1ad19632b9c580bf733e0ae8.png',
                'https://www.superstation.pro/content/images/2021/08/logo--39-.png',
                'https://upload.wikimedia.org/wikipedia/ru/thumb/2/20/KazMunayGas_logo.svg/640px-KazMunayGas_logo.svg.png'
            ],
            swiperOptions: {
                    draggable: true,
                    touchRatio: 1,
                    centeredSlides: false,
                    spaceBetween: 30,
                    loop: true,    
                    infinite: true,
                    speed: 400,
                    slidesPerView: 4,
                    // pagination: {
                    //     el: ".swiper-pagination",
                    //     dynamicBullets: false,
                    //     clickable: true,
                    // },
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                    },
                    // breakpoints: {
			        //     320: {
			        //       slidesPerView: 1.2,
                    //       spaceBetween: 4,
			        //     },
			        //     768: {
				    //       slidesPerView: 1.2,
                    //       spaceBetween: 20,
				    //     },
				    //     1150: {
				    //       slidesPerView: 1.5,
                    //       spaceBetween: 60,
				    //     }
			        // }
                },
        }
    }
}
</script>