<template>
    <v-main app>
        <section id="geomap">
            <GmapMap :center="center" :zoom="4" :options="mapStyle">
                <GmapMarker
                    v-for="(marker, index) in markers" :key="index"
                    :position="marker"
                    :clickable="true"
                    :draggable="false"
                    :icon="markerOptions"
                    @click="center=marker"
                />
            </GmapMap>
            <v-container>
                <v-row>
                    <v-col cols="12" lg="4">
                        <Countries @changeLocation="changeLocation" />
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </v-main>
</template>

<script>
import Countries from '../components/Countries.vue'

export default {
    components: {Countries},
    methods: {
        changeLocation(e){
            
             this.center = e
        }
    },
    data(){
        return {
            center: {lat: 52, lng: 35},
            markers: [
                {lat: 55.78977538604724, lng: 37.60240872612117},
                {lat: 53.395724, lng: 28.064281},
                {lat: 48.622118, lng: 66.853642},
                {lat: 41.693081, lng: 44.801559},
                {lat: 35.661703, lng: 51.367734},
                {lat: 36.209204, lng: 103.111201},
                {lat: 40.177628, lng: 44.512546},
                {lat: 40.368913, lng: 49.835029},
                {lat: 47.918501, lng: 106.917602},
                {lat: 16.831510, lng: 106.891652}
            ],
            markerOptions: {
                url: require('../assets/img/logomarker.svg'),
                size: {width: 80, height: 80, f: 'px', b: 'px',},
                scaledSize: {width: 80, height: 80, f: 'px', b: 'px',},
            },
            mapStyle: {
                scrollwheel: false,
                disableDefaultUI : true,
                styles: [
                    {
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#f5f5f5"
                        }
                        ]
                    },
                    {
                        elementType: "labels.icon",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#616161"
                        }
                        ]
                    },
                    {
                        elementType:"labels.text.stroke",
                        stylers: [
                        {
                            color: "#f5f5f5"
                        }
                        ]
                    },
                    {
                        featureType: "administrative",
                        elementType: "geometry",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "administrative.land_parcel",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "administrative.land_parcel",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#bdbdbd"
                        }
                        ]
                    },
                    {
                        featureType: "administrative.neighborhood",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "poi",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#eeeeee"
                        }
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#757575"
                        }
                        ]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#e5e5e5"
                        }
                        ]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#9e9e9e"
                        }
                        ]
                    },
                    {
                        featureType: "road",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#ffffff"
                        }
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#757575"
                        }
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#dadada"
                        }
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#616161"
                        }
                        ]
                    },
                    {
                        featureType: "road.local",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#9e9e9e"
                        }
                        ]
                    },
                    {
                        featureType: "transit",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "transit.line",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#e5e5e5"
                        }
                        ]
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#eeeeee"
                        }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                        {
                            color: "#c9c9c9"
                        }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text",
                        stylers: [
                        {
                            visibility: "off"
                        }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                        {
                            color: "#9e9e9e"
                        }
                        ]
                    }
                ]
            }
        }
    }
}
</script>